import React from "react"
import { Link } from "react-scroll"
import { Element } from "react-scroll"
import ReactWOW from "react-wow"

import ServiceIcon7 from "../../assets/images/services/service-icon7.png"
import ServiceIcon8 from "../../assets/images/services/service-icon8.png"
import ServiceIcon9 from "../../assets/images/services/service-icon9.png"
import ServiceIcon10 from "../../assets/images/services/service-icon10.png"
import ServiceIcon11 from "../../assets/images/services/service-icon11.png"
import ServiceIcon12 from "../../assets/images/services/service-icon12.png"
import ServiceShape4 from "../../assets/images/services/service-shape4.png"

const Services = () => {
  return (
    <Element name="services">
      <section className="services-area ptb-100">
        <div className="container">
          <div className="section-title">
            <h2>What Can We Offer? </h2>
          </div>

          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12">
              <ReactWOW delay=".4s" animation="fadeInLeft" scroll>
                <div className="single-services-box-item">
                  <div className="icon">
                    <img src={ServiceIcon7} alt="Service Icon" />
                  </div>
                  <h3>
                    <Link to="/service-details">Starter</Link>
                  </h3>
                  <p>
                    You’re starting to venture in the world of entrepreneurship,
                    so you need to travel light. The Starter Package covers your
                    essential needs in a lean and efficient way.
                  </p>

                  <Link
                    to="pricing"
                    spy={true}
                    smooth={true}
                    className="learn-more-btn"
                  >
                    <i className="flaticon-right"></i> Learn More
                  </Link>

                  <div className="shape">
                    <img src={ServiceShape4} alt="Service Shape" />
                  </div>
                </div>
              </ReactWOW>
            </div>

            <div className="col-lg-6 col-md-6 col-sm-12">
              <ReactWOW delay=".4s" animation="fadeInRight" scroll>
                <div className="single-services-box-item">
                  <div className="icon">
                    <img src={ServiceIcon8} alt="Service Icon" />
                  </div>
                  <h3>
                    <Link to="/service-details">Professional</Link>
                  </h3>
                  <p>
                    Your business is at a professional level and you need a more
                    advanced accounting plan that’s still light and efficient,
                    but which also covers Payroll and VAT needs while keeping
                    your business finances flowing smoothly.
                  </p>

                  <Link
                    to="pricing"
                    spy={true}
                    smooth={true}
                    className="learn-more-btn"
                  >
                    <i className="flaticon-right"></i> Learn More
                  </Link>
                  <div className="shape">
                    <img src={ServiceShape4} alt="Service Shape" />
                  </div>
                </div>
              </ReactWOW>
            </div>

            <div className="col-lg-6 col-md-6 col-sm-12">
              <ReactWOW delay=".4s" animation="fadeInLeft" scroll>
                <div className="single-services-box-item">
                  <div className="icon">
                    <img src={ServiceIcon9} alt="Service Icon" />
                  </div>
                  <h3>
                    <Link to="/service-details">Professional +</Link>
                  </h3>
                  <p>
                    Freeing up yours and your team’s time is a decisive need so
                    that you can focus on the unique value you create. We’re
                    talking about stepping in with account management, reporting
                    and company secretarial support to be the hands-free
                    accounting pillar your company needs.
                  </p>

                  <Link
                    to="pricing"
                    spy={true}
                    smooth={true}
                    className="learn-more-btn"
                  >
                    <i className="flaticon-right"></i> Learn More
                  </Link>
                  <div className="shape">
                    <img src={ServiceShape4} alt="Service Shape" />
                  </div>
                </div>
              </ReactWOW>
            </div>

            <div className="col-lg-6 col-md-6 col-sm-12">
              <ReactWOW delay=".4s" animation="fadeInRight" scroll>
                <div className="single-services-box-item wow fadeInUp">
                  <div className="icon">
                    <img src={ServiceIcon10} alt="Service Icon" />
                  </div>
                  <h3>
                    <Link to="/service-details">Tailor Made</Link>
                  </h3>
                  <p>
                    Your business is unique and so are your needs. There’s no
                    one-size-fits-all solution that matches your needs, which is
                    why we can design a tailor-made package that suits you. In
                    other words, if there’s any type of accounting support that
                    you need, we’ll have it tailored for you.
                  </p>

                  <Link
                    to="pricing"
                    spy={true}
                    smooth={true}
                    className="learn-more-btn"
                  >
                    <i className="flaticon-right"></i> Learn More
                  </Link>
                  <div className="shape">
                    <img src={ServiceShape4} alt="Service Shape" />
                  </div>
                </div>
              </ReactWOW>
            </div>
          </div>
        </div>
      </section>
    </Element>
  )
}

export default Services
