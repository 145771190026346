import React from "react"
import { Link, Element } from "react-scroll"

const Pricing = ({ selectPlan }) => {
  const onClick = e => {
    console.log(e.target.name)
    selectPlan(e.target.name)
  }

  return (
    <Element name="pricing">
      <section className="pricing-area pt-100 pb-70 bg-f4f7fe">
        <div className="container">
          <div className="section-title">
            <h2>Our Package Selections</h2>
            {/* <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna.
            </p> */}
          </div>

          <div className="row">
            <div className="col-lg-3 col-md-6">
              <div className="single-pricing-box">
                <div className="pricing-header">
                  <h3>Starter</h3>
                </div>

                {/* <div className="price">
                  <sup>$</sup> 120 <sub>/ Per week</sub>
                </div> */}

                <ul className="pricing-features">
                  <li>
                    <i className="bx bxs-badge-check"></i> Annual Accounts
                  </li>
                  <li>
                    <i className="bx bxs-badge-check"></i> Corporation Tax
                  </li>
                  <li>
                    <i className="bx bxs-badge-check"></i> Confirmation
                    Statement
                  </li>
                  <li>
                    <i className="bx bxs-badge-check"></i> Company Secreterial
                  </li>
                  <li>
                    <i className="bx bxs-x-circle red"></i> Bookkeeping
                  </li>
                  <li>
                    <i className="bx bxs-x-circle red"></i> Payroll
                  </li>
                  <li>
                    <i className="bx bxs-x-circle red"></i> VAT
                  </li>
                  <li>
                    <i className="bx bxs-x-circle red"></i> Management Accounts
                  </li>

                  <li>
                    <i className="bx bxs-x-circle red"></i> Cash Flow
                  </li>
                  <li>
                    <i className="bx bxs-x-circle red"></i> Company Formation
                  </li>
                  <li>
                    <i className="bx bxs-x-circle red"></i> Director Self
                    Assessment
                  </li>
                </ul>

                <div className="btn-box">
                  <Link
                    onClick={onClick}
                    name="Starter"
                    to="contact"
                    spy={true}
                    smooth={true}
                    className="default-btn"
                  >
                    <i className="flaticon-tick"></i>
                    Select the Plan
                    <span></span>
                  </Link>
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-md-6">
              <div className="single-pricing-box">
                <div className="pricing-header">
                  <h3>Professional</h3>
                </div>

                {/* <div className="price">
                  <sup>$</sup> 120 <sub>/ Per week</sub>
                </div> */}

                <ul className="pricing-features">
                  <li>
                    <i className="bx bxs-badge-check"></i> Annual Accounts
                  </li>
                  <li>
                    <i className="bx bxs-badge-check"></i> Corporation Tax
                  </li>
                  <li>
                    <i className="bx bxs-badge-check"></i> Confirmation
                    Statement
                  </li>
                  <li>
                    <i className="bx bxs-badge-check"></i> Company Secreterial
                  </li>
                  <li>
                    <i className="bx bxs-badge-check"></i> Bookkeeping
                  </li>
                  <li>
                    <i className="bx bxs-badge-check"></i> Payroll
                  </li>
                  <li>
                    <i className="bx bxs-badge-check"></i> VAT
                  </li>
                  <li>
                    <i className="bx bxs-x-circle red"></i> Management Accounts
                  </li>
                  <li>
                    <i className="bx bxs-x-circle red"></i> Cash Flow
                  </li>
                  <li>
                    <i className="bx bxs-x-circle red"></i> Company Formation
                  </li>
                  <li>
                    <i className="bx bxs-x-circle red"></i> Director Self
                    Assessment
                  </li>
                </ul>

                <div className="btn-box">
                  <Link
                    onClick={onClick}
                    name="Professional"
                    to="contact"
                    spy={true}
                    smooth={true}
                    className="default-btn"
                  >
                    {" "}
                    <i className="flaticon-tick"></i>
                    Select the Plan
                    <span></span>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="single-pricing-box">
                <div className="pricing-header">
                  <h3>Professional +</h3>
                </div>

                {/* <div className="price">
                  <sup>$</sup> 120 <sub>/ Per week</sub>
                </div> */}

                <ul className="pricing-features">
                  <li>
                    <i className="bx bxs-badge-check"></i> Annual Accounts
                  </li>
                  <li>
                    <i className="bx bxs-badge-check"></i> Corporation Tax
                  </li>
                  <li>
                    <i className="bx bxs-badge-check"></i> Confirmation
                    Statement
                  </li>
                  <li>
                    <i className="bx bxs-badge-check"></i> Company Secreterial
                  </li>
                  <li>
                    <i className="bx bxs-badge-check"></i> Bookkeeping
                  </li>
                  <li>
                    <i className="bx bxs-badge-check"></i> Payroll
                  </li>
                  <li>
                    <i className="bx bxs-badge-check"></i> VAT
                  </li>
                  <li>
                    <i className="bx bxs-badge-check"></i> Management Accounts
                  </li>
                  <li>
                    <i className="bx bxs-x-circle red"></i> Cash Flow
                  </li>
                  <li>
                    <i className="bx bxs-x-circle red"></i> Company Formation
                  </li>
                  <li>
                    <i className="bx bxs-x-circle red"></i> Director Self
                    Assessment
                  </li>
                </ul>

                <div className="btn-box">
                  <Link
                    onClick={onClick}
                    name="Professional Plus"
                    to="contact"
                    spy={true}
                    smooth={true}
                    className="default-btn"
                  >
                    {" "}
                    <i className="flaticon-tick"></i>
                    Select the Plan
                    <span></span>
                  </Link>
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-md-6">
              <div className="single-pricing-box">
                <div className="pricing-header">
                  <h3>Tailor Made</h3>
                </div>

                {/* <div className="price">
                  <sup>$</sup> 120 <sub>/ Per week</sub>
                </div> */}

                <ul className="pricing-features">
                  <li>
                    <i className="bx bxs-badge-check"></i> Annual Accounts
                  </li>
                  <li>
                    <i className="bx bxs-badge-check"></i> Corporation Tax
                  </li>
                  <li>
                    <i className="bx bxs-badge-check"></i> Confirmation
                    Statement
                  </li>
                  <li>
                    <i className="bx bxs-badge-check"></i> Company Secreterial
                  </li>
                  <li>
                    <i className="bx bxs-badge-check"></i> Bookkeeping
                  </li>
                  <li>
                    <i className="bx bxs-badge-check"></i> Payroll
                  </li>
                  <li>
                    <i className="bx bxs-badge-check"></i> VAT
                  </li>
                  <li>
                    <i className="bx bxs-badge-check"></i> Management Accounts
                  </li>
                  <li>
                    <i className="bx bxs-badge-check"></i> Cash Flow
                  </li>
                  <li>
                    <i className="bx bxs-badge-check"></i> Company Formation
                  </li>
                  <li>
                    <i className="bx bxs-badge-check"></i> Director Self
                    Assessment
                  </li>
                </ul>

                <div className="btn-box">
                  <Link
                    onClick={onClick}
                    name="Tailor Made"
                    to="contact"
                    spy={true}
                    smooth={true}
                    className="default-btn"
                  >
                    {" "}
                    <i className="flaticon-tick"></i>
                    Select the Plan
                    <span></span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Element>
  )
}

export default Pricing
