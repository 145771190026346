import React from "react"
import { Link } from "gatsby"
import { Element } from "react-scroll"
import ReactWOW from "react-wow"

import MapShape2 from "../../assets/images/shape/map-shape2.png"
import Partner1 from "../../assets/images/partner/partner1.png"
import Partner2 from "../../assets/images/partner/partner2.png"
import Partner3 from "../../assets/images/partner/partner3.png"
import Partner4 from "../../assets/images/partner/partner4.png"
import Partner5 from "../../assets/images/partner/partner5.png"
import Partner6 from "../../assets/images/partner/partner6.png"

const WhyChooseUs = () => {
  return (
    <Element name="about">
      <section className="why-choose-us-area pt-100 pb-70 bg-color">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-12">
              <ReactWOW delay=".6s" animation="fadeInUp" scroll>
                <div className="why-choose-us-content">
                  <h2>About Us</h2>
                  <p>
                    As a tech-focused accounting firm, we understand the nature
                    and appreciate the needs of startups, digital, creative, and
                    e-commerce businesses. If you’re doing business in any of
                    these fields, we know that the last thing you want to
                    navigate is the complex world of finance - but that you
                    nevertheless want to have the peace of mind of relying on a
                    partner that helps to keep your business finances running
                    smoothly in every way. That’s us.
                  </p>

                  <div className="map-shape2">
                    <img src={MapShape2} alt="MapShape" />
                  </div>
                </div>
              </ReactWOW>
            </div>

            <div className="col-lg-6 col-md-12">
              <ReactWOW delay=".6s" animation="fadeInDown" scroll>
                <div className="our-brand-partners-list">
                  <h2>Why Choose Us?</h2>
                  <p>
                    Being digital natives and accounting experts, we leverage
                    the latest tech — namely Xero and other cloud-based tools —
                    to provide you with the accounting and tax planning help
                    that ensures your cash flow is always under your control in
                    an effective and simplified way.
                  </p>
                </div>
              </ReactWOW>
            </div>
          </div>
        </div>
      </section>
    </Element>
  )
}

export default WhyChooseUs
