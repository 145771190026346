import React from "react"
import user1 from "../../assets/images/testimonials/client1.png"
import user2 from "../../assets/images/testimonials/client2.jpeg"
import user3 from "../../assets/images/testimonials/client3.jpeg"
import shape9 from "../../assets/images/shape/vector-shape4.png"
import shape10 from "../../assets/images/services/service-shape3.png"
import Loadable from "@loadable/component"
import { Element } from "react-scroll"
const OwlCarousel = Loadable(() => import("react-owl-carousel3"))

const options = {
  loop: true,
  nav: true,
  dots: false,
  autoplayHoverPause: true,
  autoplay: true,
  margin: 30,
  navText: [
    "<i class='flaticon-left-1'></i>",
    "<i class='flaticon-right-1'></i>",
  ],
  responsive: {
    0: {
      items: 1,
    },
    768: {
      items: 2,
    },
    992: {
      items: 2,
    },
  },
}

const Testimonials = () => {
  const [display, setDisplay] = React.useState(false)

  React.useEffect(() => {
    setDisplay(true)
  }, [])

  return (
    <Element name="testimonials">
      <section className="testimonials-area pt-100 pb-70 bg-f1f8fb">
        <div className="container">
          <div className="section-title">
            <h2>What Our Clients are Saying?</h2>
            {/* <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna.
            </p> */}
          </div>

          {display ? (
            <OwlCarousel
              className="testimonials-slides owl-carousel owl-theme"
              {...options}
            >
              <div className="single-testimonials-item">
                <p>
                  "What makes Accountpad unique is the willingness to work in a
                  way that suited me. They have been with me every step of the
                  journey, answering any questions and alleviating any doubts I
                  had."
                </p>

                <div className="client-info">
                  <div className="d-flex justify-content-center align-items-center">
                    <img src={user1} className="shadow-sm" alt="testimonials" />

                    <div className="title">
                      <h3>Ali Mohamed</h3>
                      <span>Gaoshou</span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="single-testimonials-item">
                <p>
                  "Mohamed and his team have gone above and beyond any
                  expectations I had. Their friendly demeanor and enthustiating
                  approach was a charm to work with."
                </p>

                <div className="client-info">
                  <div className="d-flex justify-content-center align-items-center">
                    <img src={user3} className="shadow-sm" alt="testimonials" />

                    <div className="title">
                      <h3>Hassan Ghazali</h3>
                      <span>Take More Photos</span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="single-testimonials-item">
                <p>
                  "No matter the problem Accountpad always finds the optimal
                  solution. This allowed me to focus on my business without the
                  stress of worrying about my accounts."
                </p>

                <div className="client-info">
                  <div className="d-flex justify-content-center align-items-center">
                    <img src={user2} className="shadow-sm" alt="testimonials" />

                    <div className="title">
                      <h3>Aaron Baines</h3>
                      <span>BuiltByBains</span>
                    </div>
                  </div>
                </div>
              </div>
            </OwlCarousel>
          ) : (
            ""
          )}
        </div>

        {/* <div className="vector-shape9">
          <img src={shape10} alt="testimonials" />
        </div>
        <div className="vector-shape10">
          <img src={shape10} alt="testimonials" />
        </div> */}
      </section>
    </Element>
  )
}

export default Testimonials
