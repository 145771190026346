import React, { useState } from "react"
import Navbar from "../components/App/Navbar"
import Footer from "../components/App/Footer"
import Layout from "../components/App/Layout"
import MainBanner from "../components/BigDataAnalysisStartup/MainBanner"
import FeaturedServices from "../components/BigDataAnalysisStartup/FeaturedServices"
import WhyChooseUs from "../components/BigDataAnalysisStartup/WhyChooseUs"
import WhatWeDo from "../components/BigDataAnalysisStartup/WhatWeDo"
import Funfacts from "../components/BigDataAnalysisStartup/Funfacts"
import Services from "../components/BigDataAnalysisStartup/Services"
import Testimonials from "../components/BigDataAnalysisStartup/Testimonials"
import WeServe from "../components/BigDataAnalysisStartup/WeServe"
import TeamMembers from "../components/BigDataAnalysisStartup/TeamMembers"
import CaseStudy from "../components/BigDataAnalysisStartup/CaseStudy"
import BlogPost from "../components/BigDataAnalysisStartup/BlogPost"
import StartProject from "../components/Common/StartProject"
import HowItWork from "../components/BigDataAnalysisStartup/HowItWork"
import Pricing from "../components/BigDataAnalysisStartup/Pricing"
import ContactForm from "../components/Contact/ContactForm"

const Home = () => {
  const [plan, setPlan] = useState()

  const selectPlan = selectedPlan => {
    setPlan(selectedPlan)
  }

  return (
    <Layout>
      <Navbar />
      <MainBanner />
      {/* <FeaturedServices /> */}
      <WhyChooseUs />
      {/* <WhatWeDo /> */}
      {/* <Funfacts /> */}
      <Services />
      <HowItWork />
      <Testimonials />
      <WeServe />
      <Pricing selectPlan={selectPlan} />
      {/* <TeamMembers /> */}
      {/* <CaseStudy /> */}
      {/* <BlogPost /> */}
      {/* <StartProject /> */}
      <ContactForm plan={plan} />
      <Footer />
    </Layout>
  )
}

export default Home
