import React from "react"
import ReactWOW from "react-wow"

const ContactInfo = () => {
  return (
    <div className="contact-info-area pt-50 pb-20">
      <div className="container">
        <div className="row">
          <div className="col-lg-4 col-md-6">
            <ReactWOW delay=".1s" animation="bounceInLeft" scroll>
              <div className="contact-info-box">
                {/* <div className="back-icon">
                                <i className='bx bx-map'></i>
                            </div> */}
                <div className="icon">
                  <i className="bx bx-map"></i>
                </div>

                <h3>Our Address</h3>
                <a
                  href="https://www.google.co.uk/maps/place/74+High+Rd+Leyton,+London+E15+2BP/@51.5529951,-0.0070179,17z/data=!3m1!4b1!4m5!3m4!1s0x48761d7eb8d9d57f:0x6fb465520ceb9742!8m2!3d51.5529918!4d-0.0048292"
                  target="_blank"
                >
                  74 High Road, London, E15 2BP United Kingdom
                </a>
              </div>
            </ReactWOW>
          </div>

          <div className="col-lg-4 col-md-6">
            <ReactWOW delay=".1s" animation="fadeInUp" scroll>
              <div className="contact-info-box">
                {/* <div className="back-icon">
                                <i className='bx bx-phone-call'></i>
                            </div> */}
                <div className="icon">
                  <i className="bx bx-phone-call"></i>
                </div>
                <h3>Contact</h3>
                <p>
                  Phone: <a href="tel:+442079657148">(+44) 207 965 7148</a>
                </p>
                <p>
                  Email:{" "}
                  <a href="mailto:mohamed@accountpad.io">
                    mohamed@accountpad.io
                  </a>
                </p>
              </div>
            </ReactWOW>
          </div>

          <div className="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
            <ReactWOW delay=".1s" animation="bounceInRight" scroll>
              <div className="contact-info-box">
                {/* <div className="back-icon">
                                <i className='bx bx-time-five'></i>
                            </div> */}
                <div className="icon">
                  <i className="bx bx-time-five"></i>
                </div>
                <h3>Hours of Operation</h3>
                <p>Monday - Friday: 09:00 - 17:00</p>
              </div>
            </ReactWOW>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ContactInfo
