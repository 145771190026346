import React, { useState, useEffect } from "react"
import starIcon from "../../assets/images/star-icon.png"
import contact from "../../assets/images/contact.png"
import { Element } from "react-scroll"
import { send } from "emailjs-com"

const ContactForm = ({ plan }) => {
  console.log(plan)
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [phone, setPhone] = useState("")
  const [message, setMessage] = useState(
    plan ? `Hi, I am interested in the ${plan} package...` : ""
  )

  console.log(message)

  useEffect(() => {
    setMessage(plan ? `Hi, I am interested in the ${plan} package...` : "")
  }, [plan ? `Hi, I am interested in the ${plan} package...` : ""])

  const [templateParams, setTemplateParams] = useState(
    message ? { message: `Hi, I am interested in the ${plan} package...` } : {}
  )

  useEffect(() => {
    setTemplateParams(message ? { message } : {})
  }, [message])

  const changeName = e => {
    setName(e.target.value)
    setTemplateParams({ ...templateParams, from_name: e.target.value })
  }

  const changeEmail = e => {
    setEmail(e.target.value)
    setTemplateParams({ ...templateParams, from_email: e.target.value })
  }

  const changePhone = e => {
    setPhone(e.target.value)
    setTemplateParams({ ...templateParams, from_phone: e.target.value })
  }

  const changeMessage = e => {
    setMessage(e.target.value)
    setTemplateParams({ ...templateParams, message: e.target.value })
  }

  const onSubmit = e => {
    e.preventDefault()

    console.log(templateParams)

    setName("")
    setEmail("")
    setPhone("")
    setMessage("")

    send(
      "service_fzs3rsv",
      "template_5om9en8",
      templateParams,
      "user_dJZUsFwue3NBbyEhosIC5"
    ).then(
      result => {
        console.log(result.text)
      },
      error => {
        console.log(error.text)
      }
    )
  }

  return (
    <Element name="contact">
      <section className="contact-area pb-100">
        <div className="contact-wrapper">
          <div className="container">
            <div className="section-title">
              <h2>Ready to Get Started?</h2>
              <p>
                Your email address will not be published. Required fields are
                marked *
              </p>
            </div>

            <div className="row">
              <div className="col-lg-6 col-md-12">
                <div className="contact-image">
                  <img src={contact} alt="contact" />
                </div>
              </div>

              <div className="col-lg-6 col-md-12">
                <div className="contact-form">
                  <form id="contactForm" onSubmit={onSubmit}>
                    <div className="row">
                      <div className="col-lg-12 col-md-6">
                        <div className="form-group">
                          <input
                            type="text"
                            name="name"
                            className="form-control"
                            required
                            placeholder="Your name*"
                            onChange={changeName}
                            value={name}
                          />
                        </div>
                      </div>

                      <div className="col-lg-12 col-md-6">
                        <div className="form-group">
                          <input
                            type="email"
                            name="email"
                            className="form-control"
                            required
                            placeholder="Your email address*"
                            onChange={changeEmail}
                            value={email}
                          />
                        </div>
                      </div>

                      <div className="col-lg-12 col-md-12">
                        <div className="form-group">
                          <input
                            type="text"
                            name="phone_number"
                            className="form-control"
                            required
                            placeholder="Your phone number*"
                            onChange={changePhone}
                            value={phone}
                          />
                        </div>
                      </div>

                      <div className="col-lg-12 col-md-12">
                        <div className="form-group">
                          <textarea
                            name="message"
                            className="form-control"
                            cols="30"
                            rows="6"
                            required
                            placeholder="Write your message...*"
                            onChange={changeMessage}
                            value={message}
                          />
                        </div>
                      </div>

                      <div className="col-lg-12 col-md-12">
                        <button type="submit" className="default-btn">
                          <i className="flaticon-tick"></i>
                          Send Message <span></span>
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Element>
  )
}

export default ContactForm
