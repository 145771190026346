import React from "react"
import { Link } from "gatsby"
import { Element } from "react-scroll"
import ReactWOW from "react-wow"

import VectorShape11 from "../../assets/images/shape/vector-shape11.png"

const WeServe = () => {
  return (
    <Element name="industries">
      <section className="industries-serve-area bg-fafafb pt-100 pb-70">
        <div className="container">
          <div className="section-title">
            <h2>Industries We Serve</h2>
            {/* <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna.
            </p> */}
          </div>

          <div className="row">
            <div className="col-lg-3 col-sm-6 col-md-6">
              <ReactWOW delay=".1s" animation="lightSpeedIn" scroll>
                <div className="single-industries-serve-box">
                  <div className="icon">
                    <i className="flaticon-rocket"></i>
                  </div>
                  Startups
                </div>
              </ReactWOW>
            </div>

            <div className="col-lg-3 col-sm-6 col-md-6">
              <ReactWOW delay=".2s" animation="lightSpeedIn" scroll>
                <div className="single-industries-serve-box">
                  <div className="icon">
                    <i className="flaticon-laptop"></i>
                  </div>
                  Digital
                </div>
              </ReactWOW>
            </div>

            <div className="col-lg-3 col-sm-6 col-md-6">
              <ReactWOW delay=".3s" animation="lightSpeedIn" scroll>
                <div className="single-industries-serve-box">
                  <div className="icon">
                    <i className="flaticon-idea"></i>
                  </div>
                  Creative
                </div>
              </ReactWOW>
            </div>

            <div className="col-lg-3 col-sm-6 col-md-6">
              <ReactWOW delay=".4s" animation="lightSpeedIn" scroll>
                <div className="single-industries-serve-box">
                  <div className="icon">
                    <i className="flaticon-shopping-basket"></i>
                  </div>
                  E-Commerce
                </div>
              </ReactWOW>
            </div>

            {/* <div className="col-lg-3 col-sm-6 col-md-6">
              <ReactWOW delay=".5s" animation="lightSpeedIn" scroll>
                <div className="single-industries-serve-box">
                  <div className="icon">
                    <i className="flaticon-house"></i>
                  </div>
                  Real Estate
                  <Link to="/service-details" className="link-btn"></Link>
                </div>
              </ReactWOW>
            </div>

            <div className="col-lg-3 col-sm-6 col-md-6">
              <ReactWOW delay=".6s" animation="lightSpeedIn" scroll>
                <div className="single-industries-serve-box">
                  <div className="icon">
                    <i className="flaticon-order"></i>
                  </div>
                  Logistics
                  <Link to="/service-details" className="link-btn"></Link>
                </div>
              </ReactWOW>
            </div>

            <div className="col-lg-3 col-sm-6 col-md-6">
              <ReactWOW delay=".7s" animation="lightSpeedIn" scroll>
                <div className="single-industries-serve-box">
                  <div className="icon">
                    <i className="flaticon-family-insurance"></i>
                  </div>
                  Insurance
                  <Link to="/service-details" className="link-btn"></Link>
                </div>
              </ReactWOW>
            </div>

            <div className="col-lg-3 col-sm-6 col-md-6">
              <ReactWOW delay=".8s" animation="lightSpeedIn" scroll>
                <div className="single-industries-serve-box">
                  <div className="icon">
                    <i className="flaticon-bitcoin"></i>
                  </div>
                  Capital Markets
                  <Link to="/service-details" className="link-btn"></Link>
                </div>
              </ReactWOW>
            </div> */}
          </div>
        </div>

        <div className="vector-shape11">
          <img src={VectorShape11} alt="Vector Shape" />
        </div>
      </section>
    </Element>
  )
}

export default WeServe
