import React from "react"
import starIcon from "../../assets/images/star-icon.png"
import process7 from "../../assets/images/process/process7.png"
import small1 from "../../assets/images/process/process-small1.png"
import small2 from "../../assets/images/process/process-small2.png"
import small3 from "../../assets/images/process/process-small3.png"
import small4 from "../../assets/images/process/process-small4.png"
import small5 from "../../assets/images/process/process-small5.png"
import small6 from "../../assets/images/process/process-small6.png"
import { Element } from "react-scroll"
import ReactWOW from "react-wow"

const HowItWork = () => {
  return (
    <Element name="process">
      <section className="process-area ptb-100 bg-fafafb">
        <div className="container">
          <div className="section-title">
            <h2>Our Process</h2>
            {/* <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna.
            </p> */}
          </div>

          <div className="row align-items-center m-0">
            <div className="col-lg-6 col-md-12 p-0">
              <div className="process-image">
                <img src={process7} alt="banner" />
              </div>
            </div>

            <div className="col-lg-6 col-md-12 p-0">
              <div className="process-content">
                <div className="row">
                  <div className="col-lg-12 col-md-6">
                    <ReactWOW delay=".4s" animation="fadeInRight" scroll>
                      <div className="single-box-item">
                        <div className="d-flex align-items-center">
                          <div className="image">
                            <img src={small1} alt="banner" />
                          </div>
                          <h3>Choose a Package</h3>
                          <div className="number">1</div>
                        </div>
                      </div>
                    </ReactWOW>
                  </div>
                  <div className="col-lg-12 col-md-6">
                    <ReactWOW delay=".6s" animation="fadeInRight" scroll>
                      <div className="single-box-item">
                        <div className="d-flex align-items-center">
                          <div className="image">
                            <img src={small2} alt="banner" />
                          </div>
                          <h3>Collect the Data</h3>
                          <div className="number">2</div>
                        </div>
                      </div>
                    </ReactWOW>
                  </div>
                  <div className="col-lg-12 col-md-6">
                    <ReactWOW delay="0.80s" animation="fadeInRight" scroll>
                      <div className="single-box-item">
                        <div className="d-flex align-items-center">
                          <div className="image">
                            <img src={small3} alt="banner" />
                          </div>
                          <h3>On-Board</h3>
                          <div className="number">3</div>
                        </div>
                      </div>
                    </ReactWOW>
                  </div>
                  <div className="col-lg-12 col-md-6">
                    <ReactWOW delay="1.0s" animation="fadeInRight" scroll>
                      <div className="single-box-item">
                        <div className="d-flex align-items-center">
                          <div className="image">
                            <img src={small4} alt="banner" />
                          </div>
                          <h3>Perform Analysis</h3>
                          <div className="number">4</div>
                        </div>
                      </div>
                    </ReactWOW>
                  </div>
                  <div className="col-lg-12 col-md-6">
                    <ReactWOW delay="1.2s" animation="fadeInRight" scroll>
                      <div className="single-box-item">
                        <div className="d-flex align-items-center">
                          <div className="image">
                            <img src={small5} alt="banner" />
                          </div>
                          <h3>Prepare Report</h3>
                          <div className="number">5</div>
                        </div>
                      </div>
                    </ReactWOW>
                  </div>
                  <div className="col-lg-12 col-md-6">
                    <ReactWOW delay="1.4s" animation="fadeInRight" scroll>
                      <div className="single-box-item">
                        <div className="d-flex align-items-center">
                          <div className="image">
                            <img src={small6} alt="banner" />
                          </div>
                          <h3>Communicate Results</h3>
                          <div className="number">6</div>
                        </div>
                      </div>
                    </ReactWOW>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Element>
  )
}

export default HowItWork
