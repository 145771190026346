import React from "react"
import { Helmet } from "react-helmet"

const SEO = () => {
  return (
    <div>
      <Helmet>
        <title>Accountpad</title>
        <meta name="description" content="Accountpad" />
        <meta name="og:title" property="og:title" content="Accountpad"></meta>
        <meta name="twitter:card" content="Accountpad"></meta>
        <link rel="canonical" href="https://accountpad.io/"></link>
        <meta
          property="og:image"
          content="https://res.cloudinary.com/dev-empty/image/upload/v1593069801/explore-learning.jpg"
        />
      </Helmet>
    </div>
  )
}

export default SEO
